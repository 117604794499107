import {getCookieValue, shortCookie} from "./CookieHelper";
import * as jose from "jose";
import WCAxios from "../services/WCAxios";
import User from "../models/User";
import {deleteLongSession} from "./LocalStorageHelper";

export default class Session {

    #currentUser = null;
    error;
    #endpoint;
    #projectID;
    #autoRefreshTimeout = null;
    #stateCheckerInterval = null;
    #offlineInterval = null;
    #callbackFn

    constructor(projectID, endpoint = '') {
        this.#projectID = projectID
        if (endpoint === '') {
            endpoint = 'https://' + projectID + '.frontendapi.corbado.io'
        }

        this.#endpoint = endpoint

        WCAxios.configure(wcInstance => {

            wcInstance.defaults.baseURL = endpoint
            wcInstance.defaults.timeout = 60 * 1000
            wcInstance.defaults.withCredentials = true

            if (projectID !== '') {
                wcInstance.defaults.headers.common['X-Corbado-ProjectID'] = projectID
            }
            wcInstance.defaults.headers.common['X-Corbado-Client-Timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone

            return wcInstance
        })
    }

    #parseJWK = async jwt => {
        const callOptions = {}
        if (this.#projectID !== '') {
            callOptions.headers = {
                'X-Corbado-ProjectID': this.#projectID
            }
        }

        const JWKS = jose.createRemoteJWKSet(new URL(this.#endpoint + "/.well-known/jwks"), callOptions)
        const { payload, protectedHeader } = await jose.jwtVerify(jwt, JWKS, {})

        return {protectedHeader, payload}
    }

    #performRefresh = () => {
        return WCAxios.get().put('/v1/sessions/refresh').then(rsp => {
            shortCookie(rsp.data)

            return rsp.data
        }).catch(err => {
            if (err.name !== 'AxiosError') {
                throw err
            }

            if (err.code === 'ERR_NETWORK') {

                this.error = 'Network error - please check cors settings'
                this.#backOnline()

                return
            }

            // If we're not logged in we get an 401
            if (err.response && err.response.status === 401) {
                this.#currentUser = null
                this.#callbackFn(this.#currentUser)
                deleteLongSession()

                return
            }
        })
    }

    #backOnline = () => {

        if (this.#offlineInterval !== null) {
            return
        }

        this.#offlineInterval = setInterval(() => {
            this.#performRefresh().then(() => {
                clearInterval(this.#offlineInterval)
                this.#offlineInterval = null
            })
        }, 10 * 10000)

    }

    #autoRefresh = () => {
        this.#performRefresh().then(rsp => {
            // This means we catch an error before
            // On success we receive a valid response
            if (rsp === null) {
                return
            }

            this.#keepSessionAlive()
        })
    }

    #keepSessionAlive = () => {
        this.#parseJWK(getCookieValue('cbo_short_session')).then(rsp => {

            const expiresInSec = Math.floor(rsp.payload.exp - ((new Date()).getTime() / 1000))
            console.log('jwt expires in ' + expiresInSec + ' sec')

            this.#currentUser = new User(rsp.payload.sub, rsp.payload.Orig, rsp.payload.Name, rsp.payload.email ?? "", rsp.payload.phone_number ?? "")
            this.#callbackFn(this.#currentUser)

            // Perform auto refresh directly in order to prevent issues with negative values with set timeout
            if (expiresInSec <= 10) {
                this.#autoRefresh()

                return
            }

            this.#autoRefreshTimeout = setTimeout(
                this.#autoRefresh,
                (expiresInSec - 10) * 1000
            )
        })

    }

    #sessionExists = () => {
        return getCookieValue('cbo_short_session') !== ''
    }

    refresh(callbackFn) {
        this.#callbackFn = callbackFn

        // We onl can register it one time
        if (this.#stateCheckerInterval !== null) {
            return
        }

        let triggeredInitialAlive = false
        if (this.#sessionExists()) {
            triggeredInitialAlive = true
            this.#keepSessionAlive()
        }

        this.#stateCheckerInterval = setInterval(() => {
            const exists = this.#sessionExists()

            if (exists && this.#autoRefreshTimeout === null) {
                if (triggeredInitialAlive) {
                    triggeredInitialAlive = false
                    return
                }

                this.#keepSessionAlive()
            } else if (!exists && this.#autoRefreshTimeout !== null) {
                clearTimeout(this.#autoRefreshTimeout)
                this.#autoRefreshTimeout = null
                this.#callbackFn(null)
            }

        }, 200)
    }

    get currentUser() {
        return this.#currentUser;
    }

    isAuthed() {
        return this.#currentUser !== null;
    }

    logout() {
        return WCAxios.get().delete('/v1/sessions/logout').then(rsp => {
            shortCookie(rsp.data)
        }).catch(err => {
            console.error(err)
        })
    }

}