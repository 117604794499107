<template>

  <span>&#9888 {{ errorMessage }}</span>

</template>

<style scoped>
span {
  margin-left: 1.4rem;
  margin-top: 2rem;
  color: var(--error-color, #FF4C51);
  font-size: 0.75rem;
}

</style>

<script>
export default {
  name: "error-field",
  props: {
    errorMessage: String
  },
}

</script>