export function setLongSession(value) {
    if (value === '' || value === undefined) {
        return
    }

    if (localStorage) {
        localStorage.setItem('cboSessionToken', value)
    } else {
        console.error('No localstorage available')
    }
}

export function getLongSession() {
    if (localStorage) {
        return localStorage.getItem('cboSessionToken') ?? ''
    }

    console.error('No localstorage available')
    return ''
}


export function deleteLongSession() {
    if (localStorage) {
        return localStorage.removeItem('cboSessionToken')
    } else {
        console.error('No localstorage available')
    }
}

