export default function (associationToken) {
    if (associationToken === undefined) {
        throw new Error('Missing attribute "association-token"')
    }

    if (!associationToken.startsWith('ast1_')) {
        throw new Error('Invalid attribute "association-token": It must start with "ast1_"')
    }

    if (associationToken.length !== 35) {
        throw new Error('Invalid attribute "association-token": The length must be exactly 35 chars')
    }
}