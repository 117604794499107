export default function (projectID) {
    if (projectID === undefined) {
        throw new Error('Missing attribute "project-id"')
    }

    if (!projectID.startsWith('pro-')) {
        throw new Error('Invalid attribute "project-id": It must start with "pro-"')
    }

/*    if (projectID.length <= 5) {
        throw new Error('Invalid attribute "project-id": The length must be at least 5')
    }*/
}