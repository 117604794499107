<template>

  <button
      :class="cssClasses"
      :data-action="dataAction"
  >
    <span class="btn-text">
      <slot></slot>
    </span>
  </button>

</template>

<style type="text/css">
button {
  font-family: var(--primary-font, sans-serif);
  padding: 0.8rem 1.4rem;
  font-size: 1rem;
  border-radius: var(--border-radius, 1.5rem);
  cursor: pointer;
  position: relative;
}

.primary-btn {
  background-color: var(--primary-color, #1953ff);
  border: none;
  color: white;
}
.primary-btn:hover {
  background-color: var(--primary-hover-color, var(--primary-color, #1953ff));
}

.secondary-btn {
  border-width: 1px;
  border-style: solid;
  border-color: var(--secondary-border-color, #1953ff);
  background-color: var(--secondary-background-color, #fff);
  color: var(--secondary-font-color, #1953ff);
}

.secondary-btn:hover {
  border-color: var(--secondary-border-hover-color, var(--secondary-border-color, #1953ff));
  background-color: var(--secondary-background-hover-color, var(--secondary-background-color, #fff));
  color: var(--secondary-font-hover-color, var(--secondary-font-color, #1953ff));
}

.full-width {
  width: 100%;
}

.loading-btn .btn-text {
  visibility: hidden;
  opacity: 0;
}

.loading-btn::after {
  content: "";
  position: absolute;
  width: 1.1rem;
  height: 1.1rem;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 0.25rem solid rgba(255, 255, 255, 0.2);
  border-top-color: #fff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

.secondary-btn.loading-btn::after {
  border: 0.25rem solid rgba(var(--primary-color-rgb, 25, 83, 255), 0.2);
  border-top-color: var(--primary-color, #1953ff);
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}
</style>

<script>
export default {
  name: "styled-btn",
  props: {
    dataAction: {
      type: String,
      default: 'continue',
    },
    type: {
      type: String,
      default: 'primary',
      validator(value) {
        return ['primary', 'secondary', ].includes(value)
      }
    },
    fullWidth: Boolean,
    isLoading: Boolean,
    classes: {
      type: String,
      default: "",
    }
  },
  computed: {
    cssClasses() {
      let classes = [
          this.type + '-btn'
      ]

      if (this.fullWidth) {
        classes.push('full-width')
      }

      if (this.isLoading) {
        classes.push('loading-btn')
      }

      if (this.classes !== '') {
        classes.push(this.classes)
      }

      return classes
    }
  }
}

</script>