<template>

  <email-link-pending></email-link-pending>

</template>

<script>
import EmailLinkPending from "../../../components/EmailLinkPending";

export default {
  components: {EmailLinkPending},
}
</script>