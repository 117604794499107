<template>

  <div @click="logout">
    <slot></slot>
  </div>

</template>

<script>
import Session from "./helper/Session";
import validateProjectID from "./helper/ValidateProjectID";
import validateEndpoint from "./helper/ValidateEndpoint";

export default {
  name: "LogoutHandler",
  props: {
    endpoint: {
      type: String,
      default: '',
    },
    'redirect-url': {
      type: String,
    },
    'project-id': {
      type: String,
    },
  },
  setup(props) {

    validateProjectID(props.projectId)
    if (props.endpoint !== '') {
      validateEndpoint(props.endpoint)
    }

    const endpoint = props.endpoint !== '' ? props.endpoint : 'https://' + props.projectId + '.frontendapi.corbado.io'

    const session = new Session(props.projectId, endpoint)

    const logout = () => {
      return session.logout().then(() => {
        if (props.redirectUrl !== '') {
          window.location = props.redirectUrl
        }
      })
    }

    return {
      logout,
    }

  },
}
</script>