import Cookies from 'js-cookie'

const myCookie = Cookies
function dropCookie(cookieDef) {
    myCookie.set(
        cookieDef.key,
        cookieDef.value,
        {
            path: cookieDef.path,
            expires: new Date(cookieDef.expires),
            secure: cookieDef.secure,
            sameSite: cookieDef.sameSite,
            domain: cookieDef.domain,
        }
    )
}

export function shortCookie(rsp) {
    if (rsp.shortSession) {
        dropCookie(rsp.shortSession)
    }
}

export function getCookieValue(a) {
    const b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
    return b ? b.pop() : '';
}