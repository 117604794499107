<template>

  <input-field-container
      :label="label"
      :errorMessage="errorMessage"
      :has-content="hasUsernameContent"
      :has-focus="hasUsernameFocus"
  >
    <slot></slot>
  </input-field-container>

</template>

<script>
import ErrorField from "./ErrorField.vue";
import InputFieldContainer from "./InputFieldContainer";
import store from "../store";
import {ref} from "vue";
export default {
  name: 'UsernameInputField',
  components: {InputFieldContainer, ErrorField},
  props: {
    label: String,
    errorMessage: String,
  },
  setup() {

    const userField = document.getElementById('corbado-username')
    const username = ref(store.state.user.username)

    const hasUsernameFocus = ref(false)
    userField.addEventListener('focusin', () => {
      hasUsernameFocus.value = true
    })
    userField.addEventListener('focusout', () => {
      hasUsernameFocus.value = false
    })
    userField.addEventListener('keyup', e => {
      username.value = e.target.value
    })
    userField.addEventListener('paste', e => {
      let clipboardData = e.clipboardData || window.clipboardData;
      let value = clipboardData.getData('Text');

      if (value.length > 1) {
        username.value = value
      }
    })

    return {
      hasUsernameFocus,
      username,
    }

  },
  computed: {
    hasUsernameContent() {
      return this.username.length > 0
    },
  }
}
</script>