import UserService from "../services/UserService";
import WebauthnService from "../services/WebauthnService";
import Logger from "../helper/Logger";

export default {
    state() {
        return {
            validationRules: {},
        }
    },
    mutations: {
        addField(state, {name, validate}) {
            state.validationRules[name] = validate
        },
    },
}