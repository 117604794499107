export default {
  // Register
  sign_up: "Registrieren",
  name: "Name",
  already_have_account: "Haben Sie schon ein Konto?",
  sign_in_instead: "Anmelden.",

  // Login
  login: "Login",
  back: "Zurück",
  welcome_back: "Willkommen! Bitte geben Sie Ihre Zugangsdaten ein",
  continue: "Weiter",
  email_address: "E-Mail-Adresse",
  dont_have_account: "Sie haben noch kein Konto?",

  email_magic_link: "E-Mail Magic Link",
  open_gmail: "In Gmail öffnen",
  open_yahoo: "In Yahoo öffnen",
  open_outlook: "In Outlook öffnen",

  resend_email: "E-Mail erneut senden",
  cancel: "Abbrechen",
  check_inbox: "Bitte überprüfen Sie Ihren Posteingang",
  other_login_options: "Weitere Möglichkeiten zum Einloggen",
  login_passwordless: "Ohne Passwort einloggen per:",
  continue_with_biometric_method: "Weiter mit",
  password: 'Passwort',

  // Append Passkey
  activate: "Aktivieren",
  login_faster: "Loggen Sie sich noch schneller mit Touch ID, Face ID oder PIN ein",
  maybe_later: "Vielleicht später",
  tired_of_passwords: "Schon wieder ein Passwort?",

  account_not_confirmed: 'Es scheint, dass Ihr Konto noch nicht bestätigt wurde. Bitte überprüfen Sie Ihren Posteingang.',
  email_was_sent_to: 'Eine E-Mail wurde verschickt an',

  invalid_email_link: 'Ungültiger Link. Bitte kopieren Sie den Link aus der E-Mail.',
  processing: 'Verarbeiten...',

  email_link_expired: 'Der Link ist ungültig oder abgelaufen.',
  passkey_cancelled: 'Login mit Passkey nicht möglich. Bitte mit dem E-Mail Magic Link einloggen, der an folgende E-Mail-Adresse geschickt wurde',

  connectionTimeout: 'Ihre Verbindung zum Server wurde unterbrochen. Probieren Sie es später erneut.',
  tooManyRequests: 'Sie haben zu viele Anfragen gestellt. Bitte probieren Sie es später erneut.',
  showDetails: 'Zeige details',
  hideDetails: 'Details ausblenden',

  invalid_logintoken_link: 'Der Logintoken konnte nicht aus der Adresse gelesen werden.',
  logintoken_link_expired: 'Der Logintoken ist ungültig oder wurde bereits eingelöst.',
}