import store from "../store";

function Logger() {

    this.debug = (message, context = null) => {
        if (!store.state.projectConfig.debug) {
            return
        }

        if (context === null) {
            console.log(message)
        } else {
            console.log(message, context)
        }
    }

    this.error = (message, context = null) => {
        if (context === null) {
            console.error(message)
        } else {
            console.error(message, context)
        }
    }
}

export default new Logger()