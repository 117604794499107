<template>

  <div :class="cssClasses">
    <slot></slot>
    <label>{{ label }}</label>
    <error-field v-if="errorMessage !== '' && errorMessage !== undefined" :error-message="errorMessage"></error-field>
  </div>

</template>

<style>
.input-effect :focus {
  outline: none;
}
.input-effect {
  position: relative;
  margin: var(--input-margin, 2rem 0 1rem 0);
  width: 100%;
}

.input-effect input[disabled] {
  color: var(--text-disabled-color, var(--text-color, #535e80));
}

.input-effect input:-webkit-autofill,
.input-effect input:-webkit-autofill:hover,
.input-effect input:-webkit-autofill:focus,
.input-effect input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px var(--primary-background-color, '#ffffff') inset !important;
  box-shadow: 0 0 0 1000px white inset;
}

/*Change text in autofill textbox*/
.input-effect input:-webkit-autofill{
  -webkit-text-fill-color: var(--text-color, #535e80)!important;
}

.input-effect input {
  color: var(--text-color, #535e80);
  font-size: 1rem;
  padding: var(--input-padding, 0.8rem 1.4rem);
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-radius: var(--border-radius, 1.5rem);
  border-color: var(--border-color, rgba(143, 155, 191, 0.5));
  position: relative;
  background: transparent;
  z-index: 2;
  box-sizing: border-box;
  -webkit-text-fill-color: var(--text-color, #535e80);
  -webkit-background-clip: text !important;
  background-clip: text !important;
}
.input-effect label {
  color: var(--light-color, #8f9bbf);
  position: absolute;
  left: 1.4rem;
  top: 0.8rem;
  transition: 0.3s;
  z-index: 1;
}

.input-effect.has-content label,
.input-effect.has-focus label {
  top: -1.1rem;
  left: 1.4rem;
  font-size: 0.75rem;
  color: var(--text-color, #535e80);
  transition: 0.3s;
}
</style>

<script>
import ErrorField from "./ErrorField.vue";
export default {
  name: 'InputFieldContainer',
  components: {ErrorField},
  props: {
    label: String,
    errorMessage: String,
    hasFocus: Boolean,
    hasContent: Boolean,
  },
  computed: {
    cssClasses() {
      let cssClasses = 'input-effect'

      if (this.hasContent) {
        cssClasses += ' has-content'
      }

      if (this.hasFocus) {
        cssClasses += ' has-focus'
      }

      return cssClasses
    }
  }
}
</script>