<template>
  <hr :class="classes">
</template>

<script>
export default {
  props: {
    classes: {
      type: String,
      default: ""
    }
  }
}
</script>

<style>
hr {
  border: none;
  border-top: 1px solid var(--light-color, #8f9bbf);
  text-align: center;
  font-size: 1rem;
  height: 0.6rem;
  overflow: visible;
  line-height: 1.5rem;
  margin-top: 1.2rem;
}

hr:after {
  background: #fff;
  content: 'OR';
  padding: 0 4px;
  position: relative;
  color: 1px solid var(--light-color, #8f9bbf);
  font-size: 1rem;
  top: -0.7rem;
}
</style>