import {createStore} from "vuex";
import pages from "./pages";
import user from "./user";
import browser from "./browser";
import emaillink from "./emaillink";
import projectConfig from "./projectConfig";
import error from "./error";
import fields from "./fields";
import webComponent from "./webComponent";

const store = createStore({
    modules: {
        pages,
        browser,
        user,
        emaillink,
        projectConfig,
        error,
        fields,
        webComponent,
    }
})

export default store