<template>
  <div
      id="corbado-passkey-associate-login"
      class="container"
      style="
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;"
  >
    <form @submit="associateLoginHandler">
      <div class="input-group">
        <input-field
            id="username"
            type="email"
            name="username"
            autocomplete="username webauthn"
            label="Email"
            :value="username"
            v-model="username"
            :error-message="errorMessage.username"
        >
        </input-field>
      </div>
      <styled-btn
          @click="associateLoginHandler"
          type="primary"
          dataAction="continue"
          :fullWidth="true"
          :isLoading="loginLoading"
      >Continue
      </styled-btn>
    </form>
  </div>
</template>

<script>
import {ref} from "vue";
import InputField from "./elements/InputField.vue";
import StyledBtn from "./elements/StyledBtn.vue";
import Logger from "./helper/Logger";
import validateProjectID from "./helper/ValidateProjectID";
import WebauthnService from "./services/WebauthnService";
import WCAxios from "./services/WCAxios";
import validateEndpoint from "./helper/ValidateEndpoint";
import UserService from "./services/UserService";

const PASSKEY_LOGIN_SUCCESSFUL = "PASSKEY_LOGIN_SUCCESSFUL";
const PASSKEY_LOGIN_FAILED = "PASSKEY_LOGIN_FAILED";
const PASSKEY_NOT_EXISTS = "PASSKEY_NOT_EXISTS";


export default {
  components: {
    StyledBtn,
    InputField,
  },
  props: {
    'project-id': {
      type: String,
      required: true,
    },
    endpoint: {
      default: '',
      type: String,
    },
  },
  setup(props, context) {
    const loginLoading = ref(false);
    const username = ref("");
    const errorMessage = ref({});
    const userSvc = new UserService();
    validateProjectID(props.projectId)
    const endpoint = props.endpoint !== '' ? props.endpoint : 'https://' + props.projectId + '.frontendapi.corbado.io'
    validateEndpoint(endpoint)

    Logger.debug("Setup PasskeyAssociateLogin", {props, endpoint});

    WCAxios.configure(wcInstance => {
      wcInstance.defaults.baseURL = endpoint
      wcInstance.defaults.headers.common['X-Corbado-ProjectID'] = props.projectId
      return wcInstance
    })

    const associateLoginHandler = (e) => {
      e.preventDefault();
      errorMessage.value = {};

      if (username.value.trim().length === 0) {
        errorMessage.value = {username: "Please enter a username"};
        return;
      }

      loginLoading.value = true;

      // TODO: loginIdentierType "email" hardcoded, later we need to make it flexible
      userSvc.DevicePasskey(username.value, "email")
          .then((rsp) => {
            Logger.debug("Device has passkey:", {rsp});

            if (!rsp.data.exists) {
              loginLoading.value = false;

              context.emit(PASSKEY_NOT_EXISTS);

              return;
            }
            Logger.debug("Start webauthn signin");

            WebauthnService.AssociateLogin(username.value)
                .then((redirectURL) => {
                  Logger.debug("Finish webauthn signin", {redirectURL});

                  context.emit(PASSKEY_LOGIN_SUCCESSFUL, {data: {redirectURL}});
                  loginLoading.value = false;

                  window.location = redirectURL
                })
                .catch((err) => {
                  if (err === null) {
                    return;
                  }

                  loginLoading.value = false;
                  Logger.debug("Webauthn signin failed with error", {err});
                  errorMessage.value = err;

                  context.emit(PASSKEY_LOGIN_FAILED, {data: {err}});
                })
          })
          .catch((err) => {
            if (err === null) {
              return;
            }

            loginLoading.value = false;
            Logger.debug("Device passkey failed with error", {err});

            errorMessage.value = err;

            context.emit(PASSKEY_NOT_EXISTS, {data: {err}});
          });
    };

    loginLoading.value = false;

    return {
      loginLoading,
      errorMessage,
      associateLoginHandler,
      username,
    };
  },
};
</script>

<style scoped>
#corbado-passkey-associate-login {
  max-width: 384px;
  box-sizing: border-box;
  box-shadow: var(--box-shadow, none);
  margin-top: var(--margin-top, inherit);
}
</style>

