import EmailLinkService from "../services/EmailLinkService"
import store from "../store";
import Logger from "../helper/Logger";

export const Pages = {
    PAGE_LOGIN: 'login',
    PAGE_REGISTER: 'register',
    PAGE_APPEND_PASSKEY: 'append-passkey',
    PAGE_EMAIL_PENDING: 'email-pending',
    PAGE_EMAIL_LINK_CONFIRM: 'email-link-confirm',
    PAGE_TIMEOUT: 'timeout',
    PAGE_TOO_MANY_REQUESTS: 'too-many-requests',
    PAGE_LOGIN_TOKEN: 'login-token',
}

export const AuthMethods = {
    WEBAUTHN: 'webauthn',
    PASSWORD: 'password',
    EMAIL_DIRECT: 'email_direct',
    EMAIL: 'email',
}

export class FlowHandler {

    constructor(store) {
        this.store = store
        this.emailLinkSvc = new EmailLinkService()
    }

    async handle(methods, allowDirect = true, excludeMethod = []) {

        let selectedMethod = ""

        Logger.debug('Handle user flow', {methods})

        if (methods.length > 0) {
            if (methods.includes('webauthn') && store.state.browser.webauthnSupported && !excludeMethod.includes('webauthn')) {
                selectedMethod = AuthMethods.WEBAUTHN
            } else if (methods.includes('email') && !methods.includes('password') && allowDirect && !excludeMethod.includes('email')) {
                selectedMethod = AuthMethods.EMAIL_DIRECT
            } else if (methods.includes('password') && !excludeMethod.includes('password')) {
                selectedMethod = AuthMethods.PASSWORD
            } else {
                selectedMethod = AuthMethods.EMAIL
            }

        }

        if (selectedMethod === AuthMethods.EMAIL_DIRECT) {
            const emailLinkID = await this.emailLinkSvc.SignIn(store.state.user.username)
            store.commit('setEMailLinkIDPending', emailLinkID)
        }

        Logger.debug('Selected flow', {selectedMethod})

        return selectedMethod
    }

}