import {Pages} from "../routing";
import validateProjectID from "./ValidateProjectID";
import validateEndpoint from "./ValidateEndpoint";

const rules = [
    // ProjectID
    props => {
        let projectID = props.projectId

        if (projectID === '') {
            projectID = props['project_id']
        }

        if (projectID === '') {
            return
        }

        validateProjectID(projectID)
    },

    // TODO AssociateToken

    // Endpoint
    props => {
        if (props.endpoint === '') {
            return
        }

        validateEndpoint(props.endpoint)
    },

    // Conditional
    props => {
        if (!['yes', 'no'].includes(props.conditional)) {
            throw new Error('Invalid conditional, should be yes or no.')
        }
    },

    // Companyimageurl
    props => {
        if (props.companyimageurl.length > 0 && !props.companyimageurl.startsWith('/') && !props.companyimageurl.startsWith('http://') && !props.companyimageurl.startsWith('https://')) {
            throw new Error('Invalid companyimageurl, should start with / or http:// or https://')
        }


        if (props.companyImageUrl.length > 0 && !props.companyImageUrl.startsWith('/') && !props.companyImageUrl.startsWith('http://') && !props.companyImageUrl.startsWith('https://')) {
            throw new Error('Invalid company-image-url, should start with / or http:// or https://')
        }
    },

    // Debug
    props => {
        if (!['yes', 'no'].includes(props.debug)) {
            throw new Error('Invalid debug, should be yes or no.')
        }
    },

    // Page
    props => {
        if (!
            [
                Pages.PAGE_TIMEOUT,
                Pages.PAGE_LOGIN,
                Pages.PAGE_EMAIL_PENDING,
                Pages.PAGE_REGISTER,
                Pages.PAGE_EMAIL_PENDING,
                Pages.PAGE_EMAIL_LINK_CONFIRM,
                Pages.PAGE_APPEND_PASSKEY,
                Pages.PAGE_TOO_MANY_REQUESTS,
            ].includes(props.page)) {
            throw new Error('Invalid page, should be yes or no.')
        }
    },
]

const ValidateProps = props => {
    rules.forEach(v => {
        v(props)
    }
)}

export default ValidateProps
