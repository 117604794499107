<template>
  <p class="text-center">{{ message }}</p>
</template>

<script>
import {ref} from "vue";
import EmailLinkService from "../services/EmailLinkService"
import store from "../store";
import {Pages} from "../routing";
import { useI18n } from '../i18n/i18nPlugin'
import StyledBtn from "../elements/StyledBtn";
import Logger from "../helper/Logger";
import UserService from "../services/UserService";

export default {
  setup() {
    const message = ref("")
    const userSvc = new UserService()
    const i18n = useI18n();

    return {
      message,
      userSvc,
      i18n,
    }
  },
  beforeMount() {
    const i18n = useI18n();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params.corbadoLoginToken === undefined) {
      this.message = i18n.$t('invalid_logintoken_link')
    } else {
      this.message = i18n.$t('processing')

      this.userSvc.LoginToken(
          params.corbadoLoginToken,
      ).then(({redirectURL, sessionToken, authMethods}) => {

        // If webauthn is not supported we can follow redirect url
        // Same is if user can webauthn but wants to use email magic link
        if (!store.state.browser.webauthnSupported || authMethods.includes('webauthn')) {
          window.location = redirectURL
          return
        }

        if (store.state.projectConfig.showPasskeyAppend) {
          // In other cases we ask for adding passkey
          store.commit('setSessionToken', sessionToken)
          store.commit('setRedirectURL', redirectURL)
          store.commit('switchPage', Pages.PAGE_APPEND_PASSKEY)
        } else {
          window.location = redirectURL
        }

      }).catch (e => {
        if (e.response.data.httpStatusCode === 400) {
          // Allow retrigger process when we have users name
          // Otherwise show back button in order to start process again

          this.message = i18n.$t('logintoken_link_expired')
        } else {
          this.message = 'Login failed with ' + e.message
        }

      })
    }
  }
}

</script>