<template>

  <div>
    <div class="heading text-center">
      <h1>{{ i18n.$t('login') }}</h1>
    </div>
    <form>
      <div class="input-group">

        <input-field
          id="username"
          type="email"
          :label="i18n.$t('email_address')"
          name="username"
          autocomplete="username webauthn"
          :value="username"
          v-model="username"
          :disabled="true"
          >
        </input-field>
        <email-link-login></email-link-login>

      </div>

    </form>

    <p
      v-if="allowUserRegistration"
      class="text-small text-center">
      {{ i18n.$t('dont_have_account') }}
      <span class="link" @click="signup" data-action="switch_to_sign_up">{{ i18n.$t('sign_up') }}.</span>
    </p>

    <p
      class="pointer text-center text-small"
      @click="back"
    >{{ i18n.$t('back') }}</p>

  </div>
</template>

<script>
import InputField from "../../../elements/InputField";
import StyledHr from "../../../elements/StyledHr";
import {computed} from "vue";
import {Pages} from "../../../routing";
import store from "../../../store"
import PasswordLogin from "../../../components/PasswordLogin";
import OtherLoginOptions from "../../../components/OtherLoginOptions";
import EmailLinkLogin from "../../../components/EmailLinkLogin";
import {useI18n} from "../../../i18n/i18nPlugin";

export default {
  components: {EmailLinkLogin, OtherLoginOptions, PasswordLogin, InputField, StyledHr},
  setup() {
    const i18n = useI18n();

    const username = computed(() => {
      return store.state.user.username
    })

    const signup = computed(() => {
      return store.commit('switchPage', Pages.PAGE_REGISTER)
    })

    const allowUserRegistration = computed(() => {
      return store.state.projectConfig.allowUserRegistration
    })

    const back = e => {
      e.preventDefault()

      store.commit('switchPage', Pages.PAGE_LOGIN)
      store.commit('setCurrentLoginMethod', '')
    }

    return {
      username,
      signup,
      back,
      allowUserRegistration,
      i18n,
    }
  },
}
</script>