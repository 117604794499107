export default {
  // Register
  sign_up: "Sign up",
  name: "Name",
  already_have_account: "You already have an account?",
  sign_in_instead: "Sign in.",

  // Login
  login: "Login",
  back: "Back",
  welcome_back: "Welcome back! Please enter your details",
  continue: "Continue",
  email_address: "Email address",
  dont_have_account: "Don't have an account yet?",

  email_magic_link: "Email magic link",
  open_gmail: "Open in Gmail",
  open_yahoo: "Open in Yahoo",
  open_outlook: "Open in Outlook",

  resend_email: "Send email again",
  cancel: "Cancel",
  check_inbox: "Check your email",
  other_login_options: "Other login options",
  login_passwordless: "Login passwordless with",
  continue_with_biometric_method: "Continue with",
  password: 'Password',

  // Append Passkey
  activate: "Activate",
  login_faster: "Login even faster with Touch ID, Face ID or PIN",
  maybe_later: "Maybe later",
  tired_of_passwords: "Tired of passwords?",

  account_not_confirmed: 'It seems like your account has not been confirmed. Please check the mail sent to',
  email_was_sent_to: 'An email was sent to',

  invalid_email_link: 'Invalid link. Please copy the link from email.',
  processing: 'Processing...',

  email_link_expired: 'Your email magic link has expired or is invalid.',
  passkey_cancelled: 'Unable to login with your passkey. Please login with the email magic link sent to',

  connectionTimeout: 'There was a timeout between you and our server. Please try again later.',
  tooManyRequests: 'You generated too many requests. Please try again later.',
  showDetails: 'Show details',
  hideDetails: 'Hide details',

  invalid_logintoken_link: 'Could not find logintoken in the current url.',
  logintoken_link_expired: 'Your logintoken is either expired or not existing.',
}