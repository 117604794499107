<template>
  <div>
    <p>
      It seems like you have set up <a href="https://docs.corbado.com/helpful-guides/webhooks" target="_blank">Corbado webhooks</a> to make Corbado exchange user information with your backend.
    </p>
    <p>
      Unfortunately, your backend is not reachable or the <a href="https://docs.corbado.com/helpful-guides/corbado-cli" target="_blank">Corbado CLI</a> is not set up correctly (e.g. when you're testing locally).
    </p>
    <p>
      Please remove webhooks URL, webhooks username and webhooks password in the <a href="https://app.corbado.com/app/settings/webhooks">developer panel</a> if you don't have existing users in your backend that you need to connect to Corbado (e.g. you're building an entirely new application).
    </p>
  </div>
</template>