import Logger from "../helper/Logger";

export default {
    state() {
        return {
            errorMessage: '',
            errorLink: '',
            errorRequestID: '',
            customError: '',
        }
    },
    mutations: {
        setError(state, {message, requestID, link, customError}) {
            Logger.error(message, link, {requestID})

            state.errorMessage = message
            state.errorLink = link ?? ''
            state.errorRequestID = requestID
            state.customError = customError ?? ''
        },
        resetError(state) {
            state.errorMessage = ''
            state.errorRequestID = ''
            state.customError = ''
        },
    },
}