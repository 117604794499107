<template>

  <div>
    <div
      v-if="authed"
    >
      <slot
          name="authed"
      ></slot>
    </div>
    <div
      v-if="!authed"
    >
      <slot
          name="unauthed"
      ></slot>
    </div>
    <slot></slot>
  </div>

</template>

<script>
import {computed, ref, watch} from "vue";
import axios from "axios";
import ParseErrorMessage from "./helper/ParseErrorMessage";
import {shortCookie} from "./helper/CookieHelper";
import Session from "./helper/Session";
import validateProjectID from "./helper/ValidateProjectID";
import validateEndpoint from "./helper/ValidateEndpoint";

export default {
  name: "AuthProvider",
  props: {
    endpoint: {
      default: '',
      type: String,
    },
    'project-id': {
      type: String,
    },
  },
  setup(props) {

    validateProjectID(props.projectId)
    if (props.endpoint !== '') {
      validateEndpoint(props.endpoint)
    }

    const endpoint = props.endpoint !== '' ? props.endpoint : 'https://' + props.projectId + '.frontendapi.corbado.io'

    const session = new Session(props.projectId, endpoint)
    const authed = ref(false)

    session.refresh(authState => {
      authed.value = authState !== null
    })

    return {
      authed,
    }

  },
}
</script>