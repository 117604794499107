function getAnchor() {
    const currentUrl = document.URL,
        urlParts   = currentUrl.split('#');

    return (urlParts.length > 1) ? urlParts[1] : null;
}

export default {
    state() {
        return {
            currentPage: getAnchor(),
            companyImageUrl: '',
            context: '',
        }
    },
    mutations: {
        switchPage(state, targetPage) {
            window.history.pushState("", "", '#' + targetPage);
            state.currentPage = targetPage
        },
        setCompanyImageUrl(state, value) {
            state.companyImageUrl = value
        },
        setContext(state, value) {
            state.context = value
        }
    }
}