import {create, get} from "@github/webauthn-json";
import HandleAxiosError from "../helper/HandleAxiosError";
import Logger from "../helper/Logger";
import WCAxios from "./WCAxios";
import {shortCookie} from "../helper/CookieHelper";
import {setLongSession} from "../helper/LocalStorageHelper";

class WebauthnService {

    SignIn(username) {
        this.AbortMediation()

        return HandleAxiosError(WCAxios.get().post('/v1/users/passkey/login/start', {
            username
        })).then(rsp => {
            Logger.debug('Webauthn challenge', {signedChallenge: rsp.data.data.challenge})

            const challenge = JSON.parse(rsp.data.data.challenge)

            return get(challenge).then(signedChallenge => {
                Logger.debug('Webauthn signed challenge', {signedChallenge: JSON.stringify(signedChallenge)})

                return WCAxios.get().post(
                    '/v1/users/passkey/login/finish',
                    {
                        signedChallenge: JSON.stringify(signedChallenge),
                    }
                ).then(rsp => {
                    shortCookie(rsp.data.data)
                    setLongSession(rsp.data.data.longSession)

                    return rsp.data.data.redirectURL
                });
            })
        })
    }

    SignUp(username, name) {
        this.AbortMediation()

        return HandleAxiosError(WCAxios.get().post('/v1/users/passkey/register/start', {
            username: username,
            fullName: name,
        })).then(rsp => {
            Logger.debug('Webauthn challenge', {signedChallenge: rsp.data.data.challenge})
            const challenge = JSON.parse(rsp.data.data.challenge)

            return create(challenge).then(signedChallenge => {
                Logger.debug('Webauthn signed challenge', {signedChallenge: JSON.stringify(signedChallenge)})

                return WCAxios.get().post(
                    '/v1/users/passkey/register/finish',
                    {
                        signedChallenge: JSON.stringify(signedChallenge),
                    }
                ).then(rsp => {
                    shortCookie(rsp.data.data)
                    setLongSession(rsp.data.data.longSession)

                    return rsp.data.data.redirectURL
                });
            })
        })
    }

    Append(sessionToken) {

        return HandleAxiosError(WCAxios.get().post('/v1/users/passkey/append/start', {}, sessionToken !== '' && sessionToken !== undefined ? {
            headers: { Authorization: `Bearer ${sessionToken}` }} : {},
        )).then(rsp => {
            Logger.debug('Webauthn challenge', {signedChallenge: rsp.data.data.challenge})
            const challenge = JSON.parse(rsp.data.data.challenge)

            return create(challenge).then(signedChallenge => {
                Logger.debug('Webauthn signed challenge', {signedChallenge: JSON.stringify(signedChallenge)})

                return WCAxios.get().post(
                    '/v1/users/passkey/append/finish',
                    {
                        signedChallenge: JSON.stringify(signedChallenge),
                    }
                ).then(rsp => {
                    setLongSession(rsp.data.data.longSession)

                    return rsp.data.data.redirectURL
                });
            })
        })
    }

    MediationStart(username) {
        this.AbortMediation()
        const controller = new AbortController()
        const signal = controller.signal
        this._mediationController = controller

        return HandleAxiosError(WCAxios.get().post('/v1/users/passkey/mediation/start', {
            username,
        })).then(rsp => {
            Logger.debug('Webauthn challenge', {challenge: rsp.data.data.challenge})

            const challenge = JSON.parse(rsp.data.data.challenge)
            challenge.mediation = "conditional"
            challenge.signal = signal

            return get(challenge).then(signedChallenge => {
                Logger.debug('Webauthn signed challenge', {signedChallenge: JSON.stringify(signedChallenge)})

                return WCAxios.get().post(
                    '/v1/users/passkey/login/finish',
                    {
                        signedChallenge: JSON.stringify(signedChallenge),
                    }
                ).then(rsp => {
                    shortCookie(rsp.data.data)
                    return {redirectURL: rsp.data.data.redirectURL, username: rsp.data.data.username, confirmedCredential: rsp.data.data.confirmedCredential}
                });
            })
        })

    }

    AbortMediation() {
        if (this._mediationController) {
            try {
                this._mediationController.abort('User chose different login')
            } catch (e) {
                Logger.error('Mediation failed', e)
            }

            this._mediationController = null
        }

    }

    AssociateStart(associationToken) {
        return HandleAxiosError(WCAxios.get().post('/v1/users/passkey/associate/start', {
            associationToken
        })).then(rsp => {

            Logger.debug('Webauthn challenge', {signedChallenge: rsp.data.data.challenge})
            const challenge = JSON.parse(rsp.data.data.challenge)

            return create(challenge).then(signedChallenge => {
                Logger.debug('Webauthn signed challenge', {signedChallenge: JSON.stringify(signedChallenge)})

                return WCAxios.get().post(
                    '/v1/users/passkey/append/finish',
                    {
                        signedChallenge: JSON.stringify(signedChallenge),
                    }
                )
            })
        })
    }

    AssociateLogin(username){
        // TODO add conditional ui
        //this.AbortMediation()

        return HandleAxiosError(WCAxios.get().post('/v1/users/passkey/login/start', {
            username
        })).then(rsp => {
            Logger.debug('Webauthn challenge', {signedChallenge: rsp.data.data.challenge})
            const challenge = JSON.parse(rsp.data.data.challenge)

            return get(challenge).then(signedChallenge => {
                Logger.debug('Webauthn signed challenge', {signedChallenge: JSON.stringify(signedChallenge)})

                return WCAxios.get().post(
                    '/v1/users/passkey/login/finish',
                    {
                        signedChallenge: JSON.stringify(signedChallenge),
                    }
                ).then(rsp => {
                    shortCookie(rsp.data.data)
                    setLongSession(rsp.data.data.longSession)

                    return rsp.data.data.redirectURL
                });
            })
        })
    }

    CheckDevicePasskeyReadiness() {
        if (window.PublicKeyCredential) {
            return window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable()
        } else {
            return Promise.resolve(false)
        }
    }
}

export default new WebauthnService()