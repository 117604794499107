<template>

  <div class="text-center">
    <h1>Too many requests</h1>

    <p>
      We have lost the connection to the<br />
      server due to many requests<br />
      from your account.
    </p>
    <p>
      Please try again later or go to
      <a class="underline" href="https://api.corbado.com/docs/api/#section/Error-types/rate_limited">docs</a><br />
      for more information.
    </p>

  </div>

  <styled-btn
      @click="backBtn"
      data-action="back"
      :isLoading="false"
  >{{ i18n.$t('back') }}</styled-btn>
</template>

<style>
.underline, .underline:active, .underline:hover, .underline:visited {
  text-decoration: underline;
  font-weight: normal;
  color: currentColor;
}
</style>

<script>
import StyledBtn from "../elements/StyledBtn";
import {useI18n} from "../i18n/i18nPlugin";
import store from "../store";
import {Pages} from "../routing";

export default {
  components: {StyledBtn},
  setup() {
    const i18n = useI18n();

    const backBtn = () => {
      store.commit('switchPage', Pages.PAGE_LOGIN)
      store.commit('resetError', {})
    }

    return {
      i18n,
      backBtn,
    }
  }

};
</script>