import Logger from "../helper/Logger";

function canUsePasskeys() {
    return new Promise(resolve => {
        if (window.PublicKeyCredential) {
            Logger.debug('Browser webauthn possible')

            return window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable().then(available => {
                if (available) {
                    Logger.debug('Platform authenticator possible')
                } else {
                    Logger.debug('Platform authenticator not possible')
                }

                resolve(available)
            }).catch(() => {
                resolve(false)
            })
        }

        return resolve(false)
    })
}

export default {
    state() {
        return {
            webauthnSupported: null,
            biometricMethod: "Face ID / Touch ID",
        }
    },
    mutations: {
        setWebauthnSupported(state, supported) {
            state.webauthnSupported = supported
        },
    },
    actions: {

        async detectWebauthnSupported({ commit }) {
            canUsePasskeys().then(supported => {
                commit('setWebauthnSupported', supported)
            }).catch(() => {
                commit('setWebauthnSupported', false)
            })
        }
    }

}