import axios from "axios";
import {getLongSession} from "../helper/LocalStorageHelper";

const WSAxios = new function () {

    let instance = axios.create();

    this.configure = (fnCallback) => {
        instance = fnCallback(instance)
    }

    this.get = () => {
        const token = getLongSession()

        if (token !== '') {
            instance.defaults.headers.common['Authorization'] = 'Bearer ' + token
        }

        return instance
    }

}

export default WSAxios