<template>
  <div
      id="corbado-passkey-associate"
      class="container"
      style="
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    "
  >
<!--    <p>Login using face scan or fingerprint next time?</p>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="40"
      width="40"
    >
      <path
        fill="#8f9bbf"
        d="M5.417 16.292q-.334-.167-.438-.563-.104-.396.104-.729 2.5-3.75 6.5-5.812 4-2.063 8.542-2.063 4.417 0 8.333 1.958 3.917 1.959
                    6.542 5.584.292.416.208.833-.083.417-.375.625-.291.25-.708.208-.417-.041-.625-.416-2.292-3.25-5.833-5-3.542-1.75-7.542-1.792-4 .042-7.5
                    1.75t-5.75 5.083q-.292.375-.729.459-.438.083-.729-.125Zm20.041 21.041q-4.958-1-7.729-4.437-2.771-3.438-2.771-8.188 0-2.208 1.5-3.75
                    1.5-1.541 3.667-1.541t3.687 1.479q1.521 1.479 1.521 3.604 0 1.375.938 2.312.937.938 2.312.938 1.334 0 2.25-.938.917-.937.917-2.312
                    0-4.75-3.417-7.979-3.416-3.229-8.208-3.229-4.875 0-8.229 3.333-3.354 3.333-3.354 8.083 0 1.625.229 3.104.229 1.48.687
                    2.771.167.5.042.834-.125.333-.5.458-.417.167-.812.021-.396-.146-.605-.646-.416-1.208-.708-2.875t-.292-3.667q0-5.541 3.979-9.52 3.98-3.98
                    9.563-3.98 5.625 0 9.625 3.875t4 9.417q0 2.167-1.5 3.688-1.5 1.52-3.667 1.52-2.208 0-3.729-1.437-1.521-1.438-1.521-3.563
                    0-1.375-.937-2.354-.938-.979-2.271-.979t-2.25.979q-.917.979-.917 2.354 0 4.125 2.292 6.98 2.292 2.854 6.792
                    3.77.416.084.562.396.146.313.104.688-.125.416-.416.625-.292.208-.834.166ZM10.542 7.417q-.5.25-.854.146-.355-.105-.563-.438-.167-.25-.083-.708.083-.459.458-.625
                    2.417-1.417 5.146-2.084 2.729-.666 5.479-.666t5.375.666q2.625.667 5.042 1.959.458.208.562.604.104.396-.062.687-.209.334-.563.542-.354.208-.771-.083-2.208-1.125-4.646-1.75-2.437-.625-4.937-.625-2.5 0-4.917.562-2.416.563-4.666 1.813Zm5
                    29.083q-2.417-2.5-3.584-5.396-1.166-2.896-1.166-6.396 0-3.833 2.708-6.604t6.625-2.771q3.875 0 6.646 2.667t2.771 6.5q0 .458-.25.812-.25.355-.709.355-.5 0-.771-.355-.27-.354-.27-.812 0-3.083-2.167-5.146-2.167-2.062-5.25-2.062t-5.208
                    2.166q-2.125 2.167-2.125 5.25 0 3.209 1 5.667 1 2.458 3.291 4.833.292.334.313.709.021.375-.188.625-.291.375-.812.333-.521-.042-.854-.375ZM29 33.75q-4.125 0-6.979-2.354t-2.854-6.813q0-.5.271-.791.27-.292.687-.292.458 0
                    .729.292.271.291.271.791 0 3.625 2.313 5.417Q25.75 31.792 29 31.792q.417 0 .958-.063.542-.062 1-.104.417 0 .688.271.271.271.271.562-.042.459-.229.688-.188.229-.521.312-.5.209-1.125.25-.625.042-1.042.042Z"
      />
    </svg>-->
    <div style="margin-top: 1rem">
      <styled-btn
        type="primary"
        dataAction="sign_up"
        :fullWidth="true"
        @click="associateHandler"
        :isLoading="associateLoading"
      >
        Create passkey
      </styled-btn>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";
import StyledBtn from "./elements/StyledBtn.vue";
import Logger from "./helper/Logger";
import validateProjectID from "./helper/ValidateProjectID";
import WebauthnService from "./services/WebauthnService";
import validateEndpoint from "./helper/ValidateEndpoint";
import validateAssociateToken from "./helper/ValidateAssociateToken";
import WCAxios from "./services/WCAxios";

const PASSKEY_CREATION_SUCCESSFUL = "PASSKEY_CREATION_SUCCESSFUL"
const PASSKEY_CREATION_FAILED = "PASSKEY_CREATION_FAILED"
const DEVICE_NOT_PASSKEY_READY = "DEVICE_NOT_PASSKEY_READY"


  export default {
    name: "PasskeyAssociate",
    components: {
      StyledBtn,
    },
    props: {
      'project-id': {
        type: String,
        required: true,
      },
      endpoint: {
        default: '',
        type: String,
      },
      associationToken: {
        type: String,
        required: true,
      }
    },

    setup(props, context) {
      const associateLoading = ref(false);
      const webauthnSvc = WebauthnService;
      validateProjectID(props.projectId)
      validateAssociateToken(props.associationToken)

      const endpoint = props.endpoint !== '' ? props.endpoint : 'https://' + props.projectId + '.frontendapi.corbado.io'
      validateEndpoint(endpoint)

      WCAxios.configure(wcInstance => {
        wcInstance.defaults.baseURL = endpoint
        wcInstance.defaults.headers.common['X-Corbado-ProjectID'] = props.projectId
        return wcInstance
      })

      const associateHandler = (e) => {
        e.preventDefault();
        associateLoading.value = true;

        Logger.debug("Initiate webauthn signup");

        webauthnSvc.CheckDevicePasskeyReadiness().then((ready) => {
          if (ready) {
            webauthnSvc
                .AssociateStart(props.associationToken)
                .then((redirectURL) => {
                  Logger.debug("Performed webauthn signup", { redirectURL });
                  associateLoading.value = false;


                  context.emit(PASSKEY_CREATION_SUCCESSFUL);
                })
                .catch((err) => {
                  associateLoading.value = false;
                  if (err === null) {
                    return;
                  }

                  Logger.error('Associate register failed', err)

                  context.emit(PASSKEY_CREATION_FAILED, {data: {err}});
                });
          } else {
            associateLoading.value = false;

            Logger.error("Device not ready for webauthn signup");

            context.emit(DEVICE_NOT_PASSKEY_READY);
          }
        })
      };

      return {
        associateHandler,
        associateLoading,
      };
    },
  };
</script>

<style scoped>
  svg {
    margin-top: 0.3rem;
  }
  * {
    color: #5D6785;
  }
  #corbado-passkey-associate {
     max-width: 384px;
     box-sizing: border-box;
     box-shadow: var(--box-shadow, none);
     margin-top: var(--margin-top, inherit);
   }
</style>
