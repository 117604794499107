<template>
  <div>
    <div class="checkbox-container">
      <input type="checkbox" :name="name" :id="name" v-model="checked"/>
      <label :for="name">
        <slot></slot>
      </label>
    </div>
    <error-field v-if="errorMsg !== '' && errorMsg !== undefined" :error-message="errorMsg"></error-field>
  </div>

</template>

<style scoped>
.checkbox-container {
  display: flex;
  align-items: flex-start;
}
label {
  word-break: break-all;
}
</style>

<script>
import ErrorField from "../elements/ErrorField.vue";
import store from "../store";
import {ref} from "vue";
export default {
  name: 'CheckBoxField',
  components: {ErrorField},
  props: {
    name: String,
    errorMessage: String,
  },
  setup({name, errorMessage}) {
    const errorMsg = ref('')
    const checked = ref(false)

    store.commit('addField', {name, validate: function () {
        errorMsg.value = ''

      if (checked.value !== true) {
        errorMsg.value = errorMessage
      }

      return errorMsg.value === ''
    }})

    return {
      errorMsg,
      checked,
    }
  }
}
</script>