import axios from "axios";
import HandleAxiosError from "../helper/HandleAxiosError";
import WCAxios from "./WCAxios";
import {shortCookie} from "../helper/CookieHelper";
import {setLongSession} from "../helper/LocalStorageHelper";

export default class UserService {

    tokenFieldName = 'accessToken'
    userDataFieldName = 'userData'

    CreateSession(accessToken, userPayload) {
        localStorage.setItem(this.tokenFieldName, accessToken)
        localStorage.setItem(this.userDataFieldName, JSON.stringify(userPayload))
    }

    AuthMethods(username) {
        return HandleAxiosError(WCAxios.get().post('/v1/users/authmethods', {username})).then(rsp => {
            return rsp.data.data
        })
    }

    ProjectConfig() {
        return HandleAxiosError(WCAxios.get().get('/v1/projects/projectConfig')).then(rsp => {
            return rsp.data.data
        })
    }

    LoginToken(token) {
        return HandleAxiosError(WCAxios.get().post('/v1/users/logintoken', {token})).then(rsp => {
            shortCookie(rsp.data.data)
            setLongSession(rsp.data.data.longSession)

            return {sessionToken: rsp.data.data.sessionToken, redirectURL: rsp.data.data.redirectURL}
        })
    }

    DevicePasskey(loginIdentifier, loginIdentifierType) {
        return HandleAxiosError(WCAxios.get().post('/v1/users/device/passkey', {
            loginIdentifier,
            loginIdentifierType
        }))
    }

}
