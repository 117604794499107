<template>

  <div class="text-center">
    <b style="margin-bottom: 1rem; display: inline-block">{{ i18n.$t('login_passwordless') }}</b>
    <email-link-login></email-link-login>

  </div>
</template>
<script>
import EmailLinkLogin from "./EmailLinkLogin";
import {useI18n} from "../i18n/i18nPlugin";

export default {
  components: {EmailLinkLogin,},
  name: "other-login-options",
  setup() {
    const i18n = useI18n();
    return {
      i18n,
    }
  }
}

</script>
<style scoped>
.email-btn {
  position: relative;
}

</style>