<template>

  <div
    v-if="showError"
  >
    <div
        class="text-center">
      <h1>Bad request</h1>

      <p>
        Something went wrong<span v-if="errorRequestID !== ''"> with<br /> Request ID {{ errorRequestID }}</span>
      </p>
      <component
          v-if="customError !== ''"
          :is="customError"
      ></component>

      <p v-if="customError === ''">
        Please check your developer console or go to developer panel.
      </p>

      <p v-if="showDetails">
        {{ errorStore }}
        <a v-if="errorLink !== ''"
           class="link"
           :href="errorLink"
           target="_blank"
        >
          {{ errorLink }}
        </a>
      </p>

    </div>

    <div
      class="row"
    >
      <div
        class="col-xs-6 "
      >
        <styled-btn
            @click="showDetailsBtn"
            data-action="back"
            :isLoading="false"
        >{{ showDetails ? i18n.$t('hideDetails') : i18n.$t('showDetails') }}</styled-btn>
      </div>
      <div
        class="col-xs-6 end-md"
      >
        <styled-btn
            @click="backBtn"
            data-action="back"
            :isLoading="false"
            type="secondary"
        >{{ i18n.$t('cancel') }}</styled-btn>
      </div>

    </div>

  </div>

  <slot v-else></slot>

</template>

<style>
.underline, .underline:active, .underline:hover, .underline:visited {
  text-decoration: underline;
  font-weight: normal;
  color: currentColor;
}

a, .link {
  text-decoration: none;
  font-weight: bold;
  color: var(--light-color, #1953ff);
  cursor: pointer;
}
</style>

<script>
import {computed, onErrorCaptured, ref} from "vue";
import store from "./store";
import StyledBtn from "./elements/StyledBtn";
import {Pages} from "./routing";
import {useI18n} from "./i18n/i18nPlugin";
import Logger from "./helper/Logger";
import WebhookError from "./components/WebhookError";

export default {
  name: 'ErrorHandler',
  components: {WebhookError, StyledBtn},
  setup() {
    const i18n = useI18n();

    const errorStore = computed(() => {
      return store.state.error.errorMessage
    })
    const errorLink = computed(() => {
      return store.state.error.errorLink
    })
    const errorRequestID = computed(() => {
      return store.state.error.errorRequestID
    })
    const customError = computed(() => {
      return store.state.error.customError
    })

    const showDetails = ref(false)
    const errorCaptured = ref(false)
    const showError = computed(() => {
      return store.state.error.errorMessage !== '' || errorCaptured.value
    })

    // Added onErrorCaptured lifecycle hook
    onErrorCaptured((e) => {
      Logger.error('Fetched error', e)
      errorCaptured.value = true

      return false;
    })

    const backBtn = () => {
      store.commit('switchPage', Pages.PAGE_LOGIN)
      store.commit('resetError', {})
    }

    const showDetailsBtn = () => {
      showDetails.value = !showDetails.value
    }

    return {
      showError,
      errorStore,
      errorRequestID,
      store,
      backBtn,
      i18n,
      showDetails,
      showDetailsBtn,
      errorLink,
      customError,
    }
  }

};
</script>