<template>
  <div>
    <styled-btn
        v-if="canEmailMagicLink"
        dataAction="email_link"
        type="primary"
        :fullWidth="true"
        @click="emailMagicLink"
        classes="email-btn"
        :is-loading="loading"
    >
      <svg style="position: absolute; left: 25px; top: 5px; height: 32px; width: 32px" viewBox="0 0 24 24">
        <path fill="currentColor" d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6M20 6L12 11L4 6H20M20 18H4V8L12 13L20 8V18Z" />
      </svg>
      {{ i18n.$t('email_magic_link') }}
    </styled-btn>
    <error-field
        v-if="errorMessage.username !== undefined && errorMessage.username !== ''"
        :error-message="errorMessage.username"
    ></error-field>

  </div>
</template>
<script>
import StyledBtn from "../elements/StyledBtn.vue";
import {computed, ref} from "vue";
import store from "../store";
import {Pages} from "../routing";
import ErrorField from "../elements/ErrorField";
import EmailLinkService from "../services/EmailLinkService";
import {useI18n} from "../i18n/i18nPlugin";
import Logger from "../helper/Logger";

export default {
  components: {ErrorField, StyledBtn},
  name: "email-link-login",
  setup() {
    const i18n = useI18n();
    const loading = ref(false)
    const errorMessage = ref({})
    const emailLinkSvc = new EmailLinkService()

    const emailMagicLink = (e) => {
      e.preventDefault()

      loading.value = true;
      return emailLinkSvc.SignIn(store.state.user.username).then(emailLinkID => {
        Logger.debug('Send email link', {username: store.state.user.username, emailLinkID})

        loading.value = false;
        store.commit('setEMailLinkIDPending', emailLinkID)
        store.commit('switchPage', Pages.PAGE_EMAIL_PENDING)
      }).catch(err => {
        loading.value = false;
        if (err !== null) {
          errorMessage.value = err
        }

      })
    }

    const canEmailMagicLink = computed(() => {
      return store.state.user.loginMethods.includes("email")
    })

    return {
      emailMagicLink,
      canEmailMagicLink,
      loading,
      errorMessage,
      i18n,
    }
  }
}

</script>
<style scoped>
.email-btn {
  position: relative;
}

</style>