import ValidateProps from "../helper/ValidateProps";

export default {
    state() {
        return {
            projectID: null,
            endpoint: null,
            conditionalUI: null,
            companyImageURL: null,
            registerTitle: null,
            loginTitle: null,
            subTitle: null,
            registerBtn: null,
            loginBtn: null,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
    },
    mutations: {
        setFromProps(state, props)  {

            ValidateProps(props)

            if (props.projectId !== '') {
                state.projectID = props.projectId
            } else if (props.project_id !== '') {
                state.projectID = props.project_id
            } else {
                state.projectID = ''
            }

            if (props.endpoint !== '') {
                state.endpoint = props.endpoint
            } else {
                state.endpoint = 'https://' + state.projectID + '.frontendapi.corbado.io'
            }

            if (props.companyImageUrl !== '') {
                state.companyImageURL = props.companyImageUrl
            } else if (props['companyimageurl'] !== '') {
                state.companyImageURL = props['companyimageurl']
            }

            state.conditionalUI = props.conditional

            if (props.registerTitle !== null) {
                state.registerTitle = props.registerTitle
            } else if (props['register_title'] !== null) {
                state.registerTitle = props['register_title']
            }

            if (props.registerBtn !== null) {
                state.registerBtn = props.registerBtn
            } else if (props['register_btn'] !== null) {
                state.registerBtn = props['register_btn']
            }

            if (props.loginTitle !== null) {
                state.loginTitle = props.loginTitle
            } else if (props['login_title'] !== null) {
                state.loginTitle = props['login_title']
            }

            if (props.loginBtn !== null) {
                state.loginBtn = props.loginBtn
            } else if (props['login_btn'] !== null) {
                state.loginBtn = props['login_btn']
            }

            if (props.subTitle !== null) {
                state.subTitle = props.subTitle
            } else if (props['sub_title'] !== null) {
                state.subTitle = props['sub_title']
            }

        }
    },
}