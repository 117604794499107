export default {
    state() {
        return {
            pendingID: null,
        }
    },
    mutations: {
        setEMailLinkIDPending(state, pendingID) {
            state.pendingID = pendingID
        },
    },
}